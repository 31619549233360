const DEACTIVATION_REQUIREMENTS = [
  'accountBalanceZero',
  'balanceEqualToAuthorizedBalance',
  'noActiveSavingsAccount',
  'noPendingChecks',
];

export function hasRequirementsFulfilled(deactivation) {
  return DEACTIVATION_REQUIREMENTS.every(key => deactivation.requirements?.[key]);
}
