import { ORGA_STATUS } from 'qonto/constants/organization';
import { hasRequirementsFulfilled } from 'qonto/utils/deactivation';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @typedef {import('./config-types').Services} Services
 */

const CACHE_KEY = 'orga-deactivation';

/**
 * Fetches deactivation data for an organization.
 *
 * @param {Services} services - The parameters for the function.
 */
let fetchDeactivationData = async ({ abilities, organizationManager }) => {
  if (abilities.cannot('read deactivation organization')) {
    return {};
  }

  let { organization } = organizationManager;

  let deactivation = await organization.getDeactivation(false).catch(() => {});

  return {
    organization,
    deactivation,
  };
};

/**
 * @type {Array<TopBannerConfig>}
 */
export const ORGANIZATION_DEACTIVATION_CONFIG = [
  {
    id: 'orga-deactivation-deactivated',
    type: 'info',
    message: (_, intl) => intl.t('subscription.account-closing.closed'),
    shouldDisplay: ({ organizationManager }) => {
      return organizationManager.organization.status === ORGA_STATUS.DEACTIVATED;
    },
  },
  {
    id: 'orga-deactivation-suspended-requester-qonto',
    componentName: 'topbar/org-deactivation',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (_, intl) => intl.t('subscription.account-closing.suspended'),
    shouldDisplay: ({ organization, deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }

      return organization.status === ORGA_STATUS.SUSPENDED && deactivation.requester === 'qonto';
    },
  },
  {
    id: 'orga-deactivation-not-suspended-requester-qonto',
    componentName: 'topbar/org-deactivation',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (model, intl) =>
      intl.t('subscription.account-closing.requirements.withdraw-funds', {
        dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
      }),
    shouldDisplay: ({ organization, deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }

      return organization.status !== ORGA_STATUS.SUSPENDED && deactivation.requester === 'qonto';
    },
  },
  {
    id: 'orga-deactivation-requester-not-qonto-requirements-fulfilled',
    componentName: 'topbar/org-deactivation',
    type: 'info',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (model, intl) =>
      intl.t('subscription.account-closing.requirements.account-will-be-permanently-closed', {
        dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
      }),
    shouldDisplay: ({ deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }

      return hasRequirementsFulfilled(deactivation);
    },
  },
  {
    id: 'orga-deactivation-requester-not-qonto-requirements-not-fulfilled',
    componentName: 'topbar/org-deactivation',
    type: 'warning',
    modelCacheKey: CACHE_KEY,
    model: fetchDeactivationData,
    message: (model, intl) =>
      intl.t('subscription.account-closing.requirements.not-met', {
        dueDate: intl.formatDate(model.deactivation.dueDate, { format: 'long' }),
      }),
    shouldDisplay: ({ deactivation }) => {
      if (!deactivation || !deactivation.ongoing) {
        return false;
      }

      return !hasRequirementsFulfilled(deactivation);
    },
  },
];
