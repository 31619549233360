import { ACCOUNT_LOW_BALANCE_CONFIG } from './account-low-balance';
import { CARDS_RENEW_CONFIG } from './cards-renew';
import { FRAUD_ALERT_CONFIG } from './fraud-alert';
import { ITALY_ISSUES_CONFIG } from './italy-issues';
import { KYC_KYB_CONFIG } from './kyc-kyb';
import { KYC_KYB_UPDATE_CONFIG } from './kyc-kyb-update';
import { ORGANIZATION_DEACTIVATION_CONFIG } from './organization-deactivation';
import { SUBSCRIPTION_CHANGE_CONFIG } from './subscription-change';

/**
 * Entry point for the top-banners configurations.
 *
 * @type {Array<import('./config-types').TopBannerConfig>}
 */
export const TOPBANNERS_CONFIG = [
  ...ORGANIZATION_DEACTIVATION_CONFIG,
  ...ITALY_ISSUES_CONFIG,
  ...SUBSCRIPTION_CHANGE_CONFIG,
  ...ACCOUNT_LOW_BALANCE_CONFIG,
  ...KYC_KYB_CONFIG,
  ...KYC_KYB_UPDATE_CONFIG,
  ...CARDS_RENEW_CONFIG,
  ...FRAUD_ALERT_CONFIG,
];
