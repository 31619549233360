import { INVITATION_TYPES } from 'qonto/constants/membership';
import { FRENCH_EINVOICE_PAYMENT_STORAGE_KEY } from 'qonto/constants/supplier-invoice';
import { DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import {
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
} from 'qonto/constants/user-actions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

export function discoveryCards({ intl, router, store, organization }) {
  return {
    'mileage-action'() {
      return {
        heading: organization.isFrench
          ? intl.t('requests.mileage.discovery.title-france')
          : intl.t('requests.mileage.discovery.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'request-mileage',
          stepId: 'journey',
          model: organization.slug,
        },
        illustration: {
          name: 'mileage',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
          'e-invoicing-consent-action',
          'e-invoicing-payment-action',
        ],
      };
    },
    'add-admin-solo-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.add-admin-solo.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'member-invite',
          stepId: 'role',
          model: organization.slug,
        },
        illustration: {
          name: 'add-admin-solo',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'document-collection-action',
          'flex-kyb-de-freelancers-action',
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-association-action',
          'kyb-onbehalf-action',
          'kyc-kyb-periodic-update-action',
          'pay-subscription-action',
        ],
      };
    },
    'client-invoice-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.create-invoice.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'receivable-invoices',
          model: organization,
        },
        illustration: {
          name: 'client-invoice',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        blockedBy: ['kyc-action'],
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'e-invoice-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.activate-e-invoicing.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'invoice-onboarding',
          stepId: 'einvoice-activation',
        },
        illustration: {
          name: 'e-invoice',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        blockedBy: ['kyc-action'],
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'invite-team-member-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.invite-member.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'member-invite',
          stepId: 'role',
          route: 'members.active.member',
          model: organization.slug,
        },
        illustration: {
          name: 'invite-team-member',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        blockedBy: ['kyc-action'],
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'supplier-invoice-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.upload-supplier-invoices.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'supplier-invoices',
          model: organization,
        },
        illustration: {
          name: 'supplier-invoice',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'invoices-integration-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.connect-integration.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'settings.connect-hub.featured',
          model: organization,
        },
        illustration: {
          name: 'invoices-integration',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'quote-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.create-quote.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'quotes',
          model: organization,
        },
        illustration: {
          name: 'quote',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'invoice-in-foreign-currencies-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.create-invoice-in-foreign-currencies.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'receivable-invoices',
          model: organization,
        },
        illustration: {
          name: 'invoice-in-foreign-currencies',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: ['french-e-invoicing-consent-action', 'french-e-invoicing-payement-action'],
      };
    },
    'invite-accountant-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.add-accountant.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'accounting-discovery',
          stepId: 'landing',
        },
        illustration: {
          name: 'invite-accountant',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'pay-later-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.pay-later.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'pay-later-transfer',
          stepId: 'invoice-upload',
        },
        illustration: {
          name: 'pay-later',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'pay-later-eligible-now-action',
          'document-collection-action',
        ],
      };
    },
    'account-aggregation-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.account-aggregation.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'external-account-import',
          stepId: 'select',
          query: { origin: router.currentRouteName },
        },
        illustration: {
          name: 'account-aggregation',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        blockedBy: ['kyc-action'],
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'payfit-integration-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.payfit-integration.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'settings.connect-hub.applications.hub-application.details.index',
          models: [organization.slug, 'payfit'],
        },
        illustration: {
          name: 'payfit-integration',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'payfit-integration-company-creation-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.payfit-integration-for-cc.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'settings.connect-hub.applications.hub-application.details.index',
          models: [organization.slug, 'payfit'],
        },
        illustration: {
          name: 'payfit-integration',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'pay-later-eligible-now-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.pay-later-eligible-now.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'financing.pay-later.in-progress',
          model: organization,
        },
        illustration: {
          name: 'pay-later',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'google-drive-integration-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.google-drive-integration.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'settings.connect-hub.applications.hub-application.details.index',
          models: [organization.slug, 'googledrivein'],
        },
        illustration: {
          name: 'google-drive-integration',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'invite-accountant-other-markets-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.invite-accountant-other-markets.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'member-invite',
          stepId: 'personal-info',
          query: {
            memberId: null,
            invitationType: INVITATION_TYPES.GUEST,
          },
        },
        illustration: {
          name: 'invite-accountant',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'attach-receipt-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.attach-receipt.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.BUTTON,
          onClick: async () => {
            let [property, direction] = DEFAULT_SORT_BY.split(':');
            let query = {
              includes: [],
              sort: { property, direction },
              pagination: { page: 1, per_page: 1 },
              organization_id: organization.id,
              filter_group: {
                conditional: 'and',
                expressions: [
                  {
                    values: ['missing'],
                    operator: 'in',
                    property: 'attachment_status',
                  },
                  {
                    values: ['pending', 'completed'],
                    operator: 'in',
                    property: 'status',
                  },
                  {
                    values: ['biller'],
                    operator: 'not_in',
                    property: 'operation_method',
                  },
                ],
              },
            };

            try {
              let { transactions } = await store.adapterFor('transaction').search(query);
              if (transactions.length === 1) {
                router.transitionTo('transactions.index', {
                  queryParams: {
                    highlight: transactions[0].id,
                  },
                });
              }
            } catch (error) {
              let errorInfo = ErrorInfo.for(error);
              if (errorInfo.shouldSendToSentry) {
                this.sentry.captureException(error);
              }
            }
          },
        },
        illustration: {
          name: 'attach-receipt',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'order-card-action'() {
      return {
        heading: intl.t('user-actions.setup-cards.order-card.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'card-new-physical',
          stepId: 'choose-card',
        },
        illustration: {
          name: 'order-card',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    'insurance-hub-action'() {
      return {
        heading: organization.isFrench
          ? intl.t('user-actions.discovery-cards.explore-insurance.fr-orga.title')
          : intl.t('user-actions.discovery-cards.explore-insurance.de-orga.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'insurance-hub.policies',
          models: [organization.slug],
        },
        illustration: {
          name: 'insurance-hub',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'french-e-invoicing-consent-action',
          'french-e-invoicing-payement-action',
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
        ],
      };
    },
    // TO DO: restore this card when we work on PDP onboarding
    // 'french-e-invoicing-consent-action'() {
    //   return {
    //     heading: intl.t('user-actions.discovery-cards.french-einvoicing-consent.title'),
    //     cta: {
    //       type: USER_ACTIONS_CTA_TYPE.LINK_TO,
    //       route: 'einvoicing-consent',
    //       models: [organization.slug],
    //       query: { origin: 'product-discovery-card' },
    //     },
    //     illustration: {
    //       name: 'e-invoice',
    //       type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
    //     },
    //   };
    // },
    'french-e-invoicing-payement-action'() {
      return {
        heading: intl.t('user-actions.discovery-cards.first-supplier-einvoice.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.BUTTON,
          onClick: () => {
            router.transitionTo('supplier-invoices.index');
            safeLocalStorage.setItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY, true);
          },
        },
        illustration: {
          name: 'e-invoice',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: ['french-e-invoicing-consent-action'],
      };
    },
    'connect-invoicing-integration-action'() {
      return {
        heading: intl.t('user-actions.setup-cards.connect-integration.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'settings.connect-hub.categories',
          models: [organization.slug, 'invoice-import'],
        },
        illustration: {
          name: 'supplier-invoice',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        hiddenBy: [
          'kyb-onbehalf-action',
          'flex-kyb-de-freelancers-action',
          'kyb-association-action',
          'pay-subscription-action',
          'kyc-kyb-periodic-update-action',
          'document-collection-action',
          'e-invoicing-consent-action',
        ],
      };
    },
  };
}
