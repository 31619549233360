import { getSupportedUnits } from './receivable-invoicing';

export const mapDocumentItemToProductPayload = item => {
  return {
    title: item.title,
    description: item.description,
    unit_price: {
      value: item.unitPrice,
      currency: item.currency,
    },
    vat_rate: item.vatRate,
    vat_exemption_code: item.vatExemptionCode,
    unit: item.unit,
    type: getServiceTypeFromUnitCode(item.unit),
  };
};

export const mapInvoicingItemToProduct = item => {
  let product = {};
  item.eachAttribute(name => {
    if (name === 'type') {
      product[name] = getServiceTypeFromUnitCode(item.unit);
    } else if (name === 'unitPrice') {
      product[name] = { value: item.unitPrice, currency: item.currency };
    } else {
      product[name] = item[name];
    }
  });
  return product;
};

export const getServiceTypeFromUnitCode = unitCode => {
  let intlNoop = { t: () => {} };
  let serviceUnitCodes = getSupportedUnits(intlNoop)
    .filter(({ isService }) => isService)
    .map(({ unitCode }) => unitCode);

  return serviceUnitCodes.includes(unitCode) ? 'service' : 'good';
};
