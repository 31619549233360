import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS } from 'qonto/constants/direct-debit-collections';
import { PROMO_BOX_LOCAL_STORAGE_KEY } from 'qonto/constants/invoice-subscriptions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class InvoiceSubscriptionManagerService extends Service {
  @tracked currentEmptyState = null;
  @tracked isSddEligible = false;

  get shouldShowPromoBox() {
    return (
      this.isSddEligible &&
      (!this.currentEmptyState ||
        // All the inform empty states are eligible
        this.currentEmptyState.startsWith('ES_I')) &&
      !safeLocalStorage.getItem(PROMO_BOX_LOCAL_STORAGE_KEY)
    );
  }

  setEmptyStateCode(code) {
    this.currentEmptyState = code;
  }

  setSddEligibility(activation) {
    this.isSddEligible =
      activation?.status === DIRECT_DEBIT_COLLECTION_ACTIVATION_STATUS.ELIGIBLE &&
      !activation?.hasGuarding;
  }
}
