import Service, { service } from '@ember/service';
import { htmlSafe } from '@ember/template';

import { isTesting, macroCondition } from '@embroider/macros';
import dayjs from 'dayjs';
import { dropTask, timeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { ErrorInfo } from 'qonto/utils/error-info';
import getAnnouncement from 'qonto/utils/get-announcement';

const THROTTLE_ANNOUNCEMENT_S = macroCondition(isTesting()) ? 1 : 5 * 60 * 1000;

const ROUTES_WITH_NO_BANNERS = [
  /^cards\.activate$/,
  /^cards\.new\./,
  /^cards\.physical-or-virtual.?\w*$/,
  /^cards\.renew\.physical$/,
  /^cards\.settings\./,
  /^flows$/,
  /^settings\.financing\.request$/,
  /^flex-kyb/,
  /^upload-power-of-attorney/,
  /^kyc/,
  /^quotes\.new/,
];

const ROUTES_WITH_NO_BANNERS_MOBILE = [
  /^settings\.connect-hub\.applications\.hub-application\.setup$/,
];

export default class BannersService extends Service {
  /** @type {import('./banner-flash-messages').default} */
  @service bannerFlashMessages;
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service abilities;
  @service router;
  @service cardsManager;
  @service sentry;
  @service zendeskLocalization;
  @service productDiscovery;
  @service periodicUpdate;
  @service store;
  @service topBanners;
  @service deviceManager;

  @reads('cardsManager.counters') cardCounters;

  async triggerBanners() {
    let { currentAccount, organization } = this.organizationManager;

    // If the organization is under registration, we don't want to display any banners
    if (organization.underRegistration) {
      return;
    }

    let { currentRouteName } = this.router;

    let isBannerHiddenForRoute = ROUTES_WITH_NO_BANNERS.some(routeRegex =>
      routeRegex.test(currentRouteName)
    );

    let isBannerHiddenForRouteOnMobile = ROUTES_WITH_NO_BANNERS_MOBILE.some(routeRegex =>
      routeRegex.test(currentRouteName)
    );

    if (isBannerHiddenForRoute || (isBannerHiddenForRouteOnMobile && this.deviceManager.isMobile)) {
      this.bannerFlashMessages.clearTopBannerMessages();
      return;
    }

    let didShowTopBanner = await this.topBanners.triggerBanners();
    if (didShowTopBanner) {
      return;
    }

    this.handleITFlexKybTopbar();
    this.handleESFlexKybTopbar();
    this.handleDEFreelancerFlexKybTopbar();

    let cbs = currentAccount ? currentAccount.coreBankingSystem : organization.coreBankingSystem;

    let options = {
      locale: this.localeManager.locale,
      cbs,
    };

    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.displayTemporaryAnnouncementTask.perform(options);
  }

  handleITFlexKybTopbar() {
    let { organization } = this.organizationManager;
    let { isFxFlexKybWaiting, legalCountry } = organization;

    if (!isFxFlexKybWaiting || legalCountry !== 'IT') {
      return;
    }

    let message = this.intl.t('it-cc.flex-kyb.topbar-banner.text', {
      link: htmlSafe(
        `<a href=${this.intl.t(
          'it-cc.flex-kyb.topbar-banner.link'
        )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
          'it-cc.flex-kyb.topbar-banner.link-text'
        )}</a>`
      ),
      htmlSafe: true,
    });
    this.bannerFlashMessages.topBannerInfo(message);
  }

  handleESFlexKybTopbar() {
    let { organization } = this.organizationManager;
    let { isFxFlexKybWaiting, legalCountry } = organization;

    if (!isFxFlexKybWaiting || legalCountry !== 'ES') {
      return;
    }

    let message = this.intl.t('es-cc.flex-kyb.topbar-banner.text', {
      htmlSafe: true,
    });
    this.bannerFlashMessages.topBannerInfo(message);
  }

  handleDEFreelancerFlexKybTopbar() {
    let { organization } = this.organizationManager;
    let { flexKyb, isDeFreelancer } = organization;

    let componentName = 'topbar/de-freelancers-flex-kyb';

    if (!flexKyb || flexKyb.status === 'accepted' || !isDeFreelancer) {
      return;
    }

    let hasFlexKybDeFreelancersAction = this.productDiscovery.hasAction(
      'flex-kyb-de-freelancers-action'
    );

    let today = new Date();

    let isInGracePeriod = today > flexKyb.startDate && today <= flexKyb.endDate;
    let isInLast5DaysOfGracePeriod =
      today > dayjs(flexKyb.endDate).subtract(5, 'day').toDate() && today <= flexKyb.endDate;
    let isInDeactivationPeriod = today > flexKyb.endDate && today <= flexKyb.deactivationDueDate;

    if (flexKyb.status === 'waiting') {
      if (isInGracePeriod && !isInLast5DaysOfGracePeriod && !hasFlexKybDeFreelancersAction) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
        let message = this.intl.t('flex-kyb.topbar-banner.30d-period-waiting', {
          count: remainingDays,
          remainingDays,
        });
        this.bannerFlashMessages.topBannerWarning(message, componentName);
      } else if (isInGracePeriod && isInLast5DaysOfGracePeriod && !hasFlexKybDeFreelancersAction) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
        let message = this.intl.t('flex-kyb.topbar-banner.30d-period-waiting-urgent', {
          count: remainingDays,
          remainingDays,
        });
        this.bannerFlashMessages.topBannerError(message, componentName);
      } else if (isInDeactivationPeriod) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.deactivationDueDate);
        let message = this.intl.t('flex-kyb.topbar-banner.closure-period-waiting', {
          count: remainingDays,
          remainingDays,
        });
        this.bannerFlashMessages.topBannerError(message, componentName);
      }
    } else if (flexKyb.status === 'reviewable') {
      let message = this.intl.t('flex-kyb.topbar-banner.reviewable');
      this.bannerFlashMessages.topBannerInfo(message, componentName);
    } else if (flexKyb.status === 'rejected') {
      if (isInGracePeriod) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
        let message = this.intl.t('flex-kyb.topbar-banner.30d-period-rejected', {
          count: remainingDays,
          remainingDays,
        });
        if (isInLast5DaysOfGracePeriod) {
          this.bannerFlashMessages.topBannerError(message, componentName);
        } else {
          this.bannerFlashMessages.topBannerWarning(message, componentName);
        }
      } else if (isInDeactivationPeriod) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.deactivationDueDate);
        let message = this.intl.t('flex-kyb.topbar-banner.closure-period-rejected', {
          count: remainingDays,
          remainingDays,
        });
        this.bannerFlashMessages.topBannerError(message, componentName);
      }
    }
  }

  displayTemporaryAnnouncementTask = dropTask(async options => {
    try {
      let messages = await getAnnouncement(options);
      for (let message of messages) {
        this.bannerFlashMessages.topBannerInfo(htmlSafe(message));
      }
      await timeout(THROTTLE_ANNOUNCEMENT_S);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
