import { assert } from '@ember/debug';
import Service, { service } from '@ember/service';
import { camelize, dasherize } from '@ember/string';

import { reads } from 'macro-decorators';

import { CODES, TYPES } from 'qonto/constants/empty-states/system';
import { ORGA_STATUS } from 'qonto/constants/organization';

export default class EmptyStatesManager extends Service {
  @service subscriptionManager;
  @service organizationManager;
  @service abilities;
  @service segment;
  @service sentry;

  @reads('subscriptionManager.currentSubscription') currentSubscription;

  /**
   * Get the empty state options based on various input conditions.
   * @public
   *
   * @param {Object} params - The input parameters.
   * @param {Object} params.config - The config object containing feature details (required).
   * @param {string} params.config.featureName - The feature name (required).
   * @param {string} params.config.featureNameCondition - `or`/`and` string determines how featureName array will be evaluated using `or` or `and` logic. (optional default to 'and')
   * @param {string} params.config.name - sub feature name (required)
   * @param {Array} params.config.variations - An array of variations for the feature's empty states (required).
   * @param {boolean} params.isOrgEligibleForFeature - Indicates if the organization is eligible for the feature (optional, defaults to true).
   * @param {boolean} params.isEmptyGlobally - Indicates if no objects have been created for the feature globally (required).
   * @param {boolean} params.isEmptyLocally - Indicates if no objects have been created for the currently active tab (required).
   * @param {boolean} params.hasActiveFilterOrSearch - Indicates if the user has activated a filter or search (required).
   * @param {Object} params.customInputs - Custom inputs object that will be checked against the config's `customInputs` object
   * @param {Object} params.abilities - Object containing the abilities that will be checked against the config's `copyAbilities` and `activateAbilities` objects
   *
   * @returns {Object} - The outputs (copies, image urls, etc...) that will be used by the presentation layer.
   *
   * @example
   * const emptyStateOptions = getEmptyStateOptions({
   *   config: {
   *     featureName: 'exampleFeature',
   *     variations: [], // Fill this array with actual variations
   *   },
   *   isEmptyGlobally: true,
   *   isEmptyLocally: false,
   *   hasActiveFilterOrSearch: true,
   *   isOrgEligibleForFeature: true,
   *   abilities: {
   *      canCreate: this.abilities.can('create invoice),
   *      canReview: this.abilities.can('review invoice),
   *   }
   *   customInputs: {
   *      isQuotaLimited: this.isQuotaLimited,
   *      tab: this.currentTab,
   *      isSubAccountClosed: this.isAccountClosed,
   *   },
   * });
   */
  getEmptyStateOptions({
    config,
    isEmptyGlobally,
    isEmptyLocally,
    hasActiveFilterOrSearch,
    isOrgEligibleForFeature = true,
    customInputs = {},
    abilities = {},
  }) {
    assert('config is required!', config);
    assert('isEmptyGlobally is required!', typeof isEmptyGlobally === 'boolean');
    assert('isEmptyLocally is required!', typeof isEmptyLocally === 'boolean');
    assert('hasActiveFilterOrSearch is required!', typeof hasActiveFilterOrSearch === 'boolean');

    let emptyStateCode = this.getEmptyStateCode({
      config,
      isOrgEligibleForFeature,
      isEmptyGlobally,
      isEmptyLocally,
      hasActiveFilterOrSearch,
    });

    if (!emptyStateCode) {
      this.#captureException('no empty state code', {
        shouldShowEmptyState: isEmptyGlobally || isEmptyLocally,
        featureName: config.name,
        isEmptyGlobally,
        isEmptyLocally,
      });
      return null;
    }

    let configVariations = config.variations[emptyStateCode.typeVariation];

    let name = dasherize(config.name);
    let output = this.#findVariation({
      configVariations,
      customInputs,
      abilities,
      name,
      featureName: config.featureName,
    });

    if (!output) {
      this.#captureException('no empty state variation', {
        shouldShowEmptyState: isEmptyGlobally || isEmptyLocally,
        featureName: config.name,
        isEmptyGlobally,
        isEmptyLocally,
        emptyStateCode,
      });
    }

    return output;
  }

  #findVariation({ configVariations, customInputs, abilities, featureName, name }) {
    let { variations: copyVariations, ...rest } = configVariations;
    let copyVariation = copyVariations.find(copyVariation => {
      // User must have the cta related abilities
      if (copyVariation.inputs?.activateAbilities?.some(abilityName => !abilities[abilityName])) {
        return false;
      }

      // User must have the copy related abilities
      if (copyVariation.inputs?.copyAbilities?.some(abilityName => !abilities[abilityName])) {
        return false;
      }

      if (
        copyVariation.inputs?.customInputs &&
        Object.entries(copyVariation.inputs.customInputs).some(
          ([key, element]) => element !== customInputs[key]
        )
      ) {
        return false;
      }

      return true;
    });

    if (!copyVariation || !copyVariation.output) return undefined;

    return { ...rest, ...copyVariation.output, featureName, name };
  }

  get isOrgDeactivatedOrSuspended() {
    return [ORGA_STATUS.DEACTIVATED, ORGA_STATUS.SUSPENDED].includes(
      this.organizationManager.organization.status
    );
  }

  get extraMembershipPermissions() {
    return Object.entries(this.organizationManager.membership.customPermissions?.groups || {})
      .filter(val => val[1] === true)
      .map(([key]) => key);
  }

  isFeatureInPricePlan(featureNames, featureNameCondition) {
    let featuresObject = this.subscriptionManager?.features;
    if (!featuresObject) {
      return false;
    }
    if (!Array.isArray(featureNames)) {
      featureNames = [featureNames];
    }
    if (featureNameCondition === 'or') {
      return featureNames.some(featureName => featuresObject[camelize(featureName)]);
    } else {
      return featureNames.every(featureName => featuresObject[camelize(featureName)]);
    }
  }

  #isFeatureInTrialPlan(featureName) {
    return !Array.isArray(featureName)
      ? Boolean(this.currentSubscription?.findTrial(featureName))
      : false;
  }

  #isFreeTrialActive() {
    return Boolean(this.currentSubscription?.activeTrial?.previous_product_id);
  }

  #hasSubscriptionUpdatePermission() {
    return this.abilities.can('update subscription');
  }

  getEmptyStateCode({
    config,
    isOrgEligibleForFeature,
    isEmptyGlobally,
    isEmptyLocally,
    hasActiveFilterOrSearch,
  }) {
    if (this.isOrgDeactivatedOrSuspended) {
      return null;
    }

    let hasNoSearchResults = isEmptyLocally && hasActiveFilterOrSearch;

    let { featureName, featureNameCondition, variations } = config;

    let {
      activateConfigVariations,
      upsellConfigVariations,
      tryConfigVariations,
      informConfigVariations,
    } = this.#getVariationsPerType(variations);

    if (
      activateConfigVariations.length > 0 &&
      this.canActivate({
        featureName,
        isEmptyGlobally,
        featureNameCondition,
      })
    ) {
      let typeVariation = this.getActivateVariation({
        isOrgEligibleForFeature,
        activateConfigVariations,
      });
      if (typeVariation) {
        return {
          type: TYPES.ACTIVATE,
          typeVariation,
        };
      }
    }

    if (
      upsellConfigVariations.length > 0 &&
      this.canUpsell({
        featureName,
        featureNameCondition,
      })
    ) {
      let typeVariation = this.getUpsellVariation({
        featureName,
        featureNameCondition,
        isEmptyGlobally,
        upsellConfigVariations,
      });
      if (typeVariation) {
        return {
          type: TYPES.UPSELL,
          typeVariation,
        };
      }
    }

    if (
      tryConfigVariations.length > 0 &&
      this.canTry({
        featureName,
        featureNameCondition,
      })
    ) {
      let typeVariation = this.getTryVariation({
        featureName,
        featureNameCondition,
        tryConfigVariations,
        isEmptyGlobally,
      });
      if (typeVariation) {
        return {
          type: TYPES.TRY,
          typeVariation,
        };
      }
    }

    if (informConfigVariations.length > 0) {
      let typeVariation = this.getInformVariation({
        isEmptyGlobally,
        isEmptyLocally,
        hasNoSearchResults,
        informConfigVariations,
      });

      if (this.canInform({ featureName, featureNameCondition }) || typeVariation === 'ES_I3') {
        if (typeVariation) {
          return {
            type: TYPES.INFORM,
            typeVariation,
          };
        }
      }
    }

    return null;
  }

  #getVariationsPerType(variations) {
    let variationsKeys = Object.keys(variations);
    return {
      activateConfigVariations: variationsKeys.filter(key => key.startsWith(TYPES.ACTIVATE)),
      upsellConfigVariations: variationsKeys.filter(key => key.startsWith(TYPES.UPSELL)),
      tryConfigVariations: variationsKeys.filter(key => key.startsWith(TYPES.TRY)),
      informConfigVariations: variationsKeys.filter(key => key.startsWith(TYPES.INFORM)),
    };
  }

  canActivate({ featureName, featureNameCondition, isEmptyGlobally }) {
    return (
      this.isFeatureInPricePlan(featureName, featureNameCondition) &&
      // User has not started using the feature at all
      isEmptyGlobally
    );
  }

  getActivateVariation = ({ isOrgEligibleForFeature, activateConfigVariations }) => {
    let hasA2 = activateConfigVariations.includes(CODES.ES_A2);
    let hasA3 = activateConfigVariations.includes(CODES.ES_A3);

    if (hasA2 && !isOrgEligibleForFeature) {
      return CODES.ES_A2;
    }
    if (hasA3) {
      return CODES.ES_A3;
    }

    return null;
  };

  canUpsell({ featureName, featureNameCondition }) {
    return (
      this.#hasSubscriptionUpdatePermission() &&
      !this.#isFeatureInTrialPlan(featureName, featureNameCondition)
    );
  }

  getUpsell_1({ featureName, featureNameCondition, isEmptyGlobally }) {
    if (!this.isFeatureInPricePlan(featureName, featureNameCondition) && isEmptyGlobally) {
      return CODES.ES_U1;
    }
    return null;
  }

  getUpsell_2({ featureName, featureNameCondition, isEmptyGlobally }) {
    if (!this.isFeatureInPricePlan(featureName, featureNameCondition) && !isEmptyGlobally) {
      return CODES.ES_U2;
    }
    return null;
  }

  getUpsellVariation = ({
    featureName,
    featureNameCondition,
    isEmptyGlobally,
    upsellConfigVariations,
  }) => {
    let hasU1 = upsellConfigVariations.includes(CODES.ES_U1);
    let hasU2 = upsellConfigVariations.includes(CODES.ES_U2);

    if (hasU1 && isEmptyGlobally) {
      return this.getUpsell_1({ featureName, featureNameCondition, isEmptyGlobally });
    } else if (hasU2) {
      return this.getUpsell_2({ featureName, featureNameCondition, isEmptyGlobally });
    }
  };

  canTry({ featureName, featureNameCondition }) {
    return (
      this.#hasSubscriptionUpdatePermission() &&
      this.#isFeatureInTrialPlan(featureName, featureNameCondition) &&
      !this.#isFreeTrialActive()
    );
  }

  getTry_1({ featureName, isEmptyGlobally, featureNameCondition }) {
    if (!this.isFeatureInPricePlan(featureName, featureNameCondition) && isEmptyGlobally) {
      return CODES.ES_T1;
    }
    return null;
  }

  getTry_2({ featureName, isEmptyGlobally, featureNameCondition }) {
    if (!this.isFeatureInPricePlan(featureName, featureNameCondition) && !isEmptyGlobally) {
      return CODES.ES_T2;
    }
    return null;
  }

  getTryVariation = ({
    featureName,
    featureNameCondition,
    isEmptyGlobally,
    tryConfigVariations,
  }) => {
    let hasT1 = tryConfigVariations.includes(CODES.ES_T1);
    let hasT2 = tryConfigVariations.includes(CODES.ES_T2);

    if (hasT1 && isEmptyGlobally) {
      return this.getTry_1({ featureName, featureNameCondition, isEmptyGlobally });
    } else if (hasT2) {
      return this.getTry_2({ featureName, featureNameCondition, isEmptyGlobally });
    }
  };

  canInform({ featureName, featureNameCondition }) {
    return this.isFeatureInPricePlan(featureName, featureNameCondition);
  }

  getInform_1({ isEmptyGlobally, isEmptyLocally, hasNoSearchResults }) {
    if (!isEmptyGlobally && isEmptyLocally && !hasNoSearchResults) {
      return CODES.ES_I1;
    }
    return null;
  }

  getInform_2({ isEmptyGlobally, isEmptyLocally, hasNoSearchResults }) {
    if (!isEmptyGlobally && isEmptyLocally && hasNoSearchResults) {
      return CODES.ES_I2;
    }
    return null;
  }

  getInform_3({ isEmptyGlobally }) {
    if (isEmptyGlobally) {
      return CODES.ES_I3;
    }
    return null;
  }

  getInformVariation = ({
    isEmptyGlobally,
    isEmptyLocally,
    hasNoSearchResults,
    informConfigVariations,
  }) => {
    let hasI1 = informConfigVariations.includes(CODES.ES_I1);
    let hasI2 = informConfigVariations.includes(CODES.ES_I2);
    let hasI3 = informConfigVariations.includes(CODES.ES_I3);

    return (
      (hasI1 && this.getInform_1({ isEmptyGlobally, isEmptyLocally, hasNoSearchResults })) ||
      (hasI2 && this.getInform_2({ isEmptyGlobally, isEmptyLocally, hasNoSearchResults })) ||
      (hasI3 && this.getInform_3({ isEmptyGlobally }))
    );
  };

  trackCta(emptyStateRevampOptions, origin) {
    if (emptyStateRevampOptions) {
      let trackingData = emptyStateRevampOptions.tracking?.({
        isClickEvent: true,
        isEmptyState: true,
        origin,
      });

      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  #captureException(
    cause,
    { shouldShowEmptyState, featureName, isEmptyGlobally, isEmptyLocally, emptyStateCode }
  ) {
    if (!this.isOrgDeactivatedOrSuspended && shouldShowEmptyState) {
      // An empty state should be shown but output is null because the config variation was not found
      this.sentry.captureException(
        new Error(`Empty State for feature:${featureName} cannot be shown due to ${cause}`),
        {
          cft: 'pricing',
          captureContext: {
            extra: {
              isEmptyGlobally,
              isEmptyLocally,
              emptyStateCode,
            },
          },
        }
      );
    }
    return null;
  }
}
