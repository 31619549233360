import {
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
} from 'qonto/constants/user-actions';

export function requiredCards({ intl, organization }) {
  return {
    'pay-subscription-action'() {
      return {
        showRequiredBadge: true,
        heading: intl.t('user-actions.required-cards.pay-for-subscription.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'payment-activation',
          stepId: 'context',
        },
        illustration: {
          name: 'pay-subscription',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      };
    },
    'kyc-kyb-periodic-update-action'() {
      return {
        showRequiredBadge: true,
        heading: intl.t('user-actions.required-cards.kyc-kyb-periodic-update.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'settings.company-profile',
          model: organization,
          text: intl.t('user-actions.required-cards.kyc-kyb-periodic-update.cta'),
        },
        illustration: {
          name: 'kyb-pending',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      };
    },
    'document-collection-action'() {
      return {
        showRequiredBadge: true,
        heading: intl.t('user-actions.required-cards.document-collection.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'upload-periodic-update-documents',
          model: organization,
          text: intl.t('user-actions.required-cards.document-collection.cta'),
        },
        illustration: {
          name: 'file-upload',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      };
    },
  };
}
