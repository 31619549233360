import JSONAPISerializer from 'qonto/serializers/-json-api';

export default class DocumentItemSerializer extends JSONAPISerializer {
  normalize(modelClass, payload) {
    payload.attributes.currency = payload.attributes.unit_price?.currency;
    payload.attributes.unit_price = payload.attributes.unit_price?.value;

    return super.normalize(modelClass, payload);
  }

  serialize(snapshot, option) {
    let json = super.serialize(snapshot, option);

    // Serialize amounts to MoneyObject using quote currency
    if (json.data.attributes.unit_price) {
      json.data.attributes.unit_price = {
        value: json.data.attributes.unit_price,
        currency: json.data.attributes.currency,
      };
    }

    return json;
  }
}
