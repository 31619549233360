// @ts-nocheck
import Service, { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

import { apiBaseURL, cashFlowCategoriesNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import type { CashflowTimeseries } from 'qonto/react/api/models/cash-flow-timeseries';
import { getPeriodTimeframeInterval } from 'qonto/react/utils/cash-flow-timeseries/period-timeframe-interval';
import {
  CashflowPeriodInterval,
  CashflowPeriodKey,
  type CashflowPeriod,
} from 'qonto/react/models/cash-flow-period';
import { mapTimeseriesToStatistics } from 'qonto/react/components/cash-flow/adapters/timeseries-statistics';

export default class CashFlowTimeseriesManager extends Service {
  @service networkManager;
  @service sentry;

  fetchTimeseriesTask = dropTask(async (period: CashflowPeriod, bankAccounts: string) => {
    const timeframe = getPeriodTimeframeInterval(period);
    const request = {
      bank_account_ids: bankAccounts,
      ...timeframe,
    };

    try {
      let response: CashflowTimeseries = await this.networkManager.request(
        `${apiBaseURL}/${cashFlowCategoriesNamespace}/cash-flow/timeseries`,
        {
          method: 'POST',
          body: JSON.stringify(request),
        }
      );

      return mapTimeseriesToStatistics(response);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
