import Service, { service } from '@ember/service';

import { TOPBANNERS_CONFIG } from './top-banners/index';

/**
 * @typedef {import('./top-banners/config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * This service will replace the `banners` one.
 */
export default class TopBannersService extends Service {
  /** @type {import('./banner-flash-messages').default} */
  @service bannerFlashMessages;

  /** @type {import('./intl').default} */
  @service intl;

  /** @type {import('./sentry').default} */
  @service sentry;

  @service router;
  @service store;

  @service abilities;
  @service organizationManager;
  @service zendeskLocalization;
  @service productDiscovery;
  @service periodicUpdate;
  @service onBehalfRegistration;

  @service cardsManager;

  /**
   * Display the top-banners.
   * @param {Array<TopBannerConfig>} configuration
   * @returns {Promise<boolean>}
   */
  async triggerBanners(configuration = TOPBANNERS_CONFIG) {
    let cache = new Map();

    for (let config of configuration) {
      let { shouldDisplay: shouldDisplayFn, model: modelFn, modelCacheKey } = config;

      let services = {
        router: this.router,
        store: this.store,
        sentry: this.sentry,
        abilities: this.abilities,
        organizationManager: this.organizationManager,
        zendeskLocalization: this.zendeskLocalization,
        productDiscovery: this.productDiscovery,
        periodicUpdate: this.periodicUpdate,
        onBehalfRegistration: this.onBehalfRegistration,
        cardsManager: this.cardsManager,
      };

      let model;

      try {
        if (modelFn && modelCacheKey && cache.has(modelCacheKey)) {
          model = cache.get(modelCacheKey);
        } else if (modelFn && modelCacheKey && !cache.has(modelCacheKey)) {
          model = await modelFn(services);
          cache.set(modelCacheKey, model);
        } else if (modelFn) {
          model = await modelFn(services);
        } else {
          // Defaults to the services, so we can leave the `model` function optional.
          model = services;
        }
      } catch (error) {
        this.sentry.captureException(error, {
          captureContext: {
            extra: {
              description: `top-banners: model function errored for top-banner with id ${config.id}`,
            },
          },
        });

        continue;
      }

      let shouldDisplay = false;
      try {
        shouldDisplay = shouldDisplayFn(model);
      } catch (error) {
        this.sentry.captureException(error, {
          captureContext: {
            extra: {
              description: `top-banners: shouldDisplay function errored for top-banner with id ${config.id}`,
            },
          },
        });
      }

      if (!shouldDisplay) {
        continue;
      }

      let message;
      try {
        message = config.message(model, this.intl);
      } catch (error) {
        this.sentry.captureException(error, {
          captureContext: {
            extra: {
              description: `top-banners: message function errored for top-banner with id ${config.id}`,
            },
          },
        });

        continue;
      }

      let extraProps = {};
      if (config.extraProps) {
        try {
          extraProps = config.extraProps(model);
        } catch (error) {
          this.sentry.captureException(error, {
            captureContext: {
              extra: {
                description: `top-banners: extraProps function errored for top-banner with id ${config.id}`,
              },
            },
          });
          continue;
        }
      }

      let componentName = config.componentName ?? 'top-banner';

      let type = config.type ?? 'info';

      switch (type) {
        case 'info':
          this.bannerFlashMessages.topBannerInfo(message, componentName, config.id, extraProps);
          return true;
        case 'warning':
          this.bannerFlashMessages.topBannerWarning(message, componentName, config.id, extraProps);
          return true;
        case 'error':
          this.bannerFlashMessages.topBannerError(message, componentName, config.id, extraProps);
          return true;
      }
    }

    return false;
  }
}
