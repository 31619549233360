/**
 *
 * @param {number | string} firstNumber
 * @param {number | string} secondNumber
 * @param {number | string} numberOfDecimals
 * @returns { number } rounded amount with original signum
 */
export function multiplyAndRound(firstNumber, secondNumber, numberOfDecimals) {
  let multipledAmount =
    parseFloat(firstNumber || 0) * parseFloat(secondNumber || 0) * numberOfDecimals;
  let signum = Math.sign(multipledAmount);
  return (signum * Math.round(Math.abs(multipledAmount))) / numberOfDecimals;
}

/**
 * Returns a new string with no more than {limit} lines.
 * Further new line characters will be considered white spaces
 * @param {string} string
 * @param {limit} number
 * @returns {string} string
 */
export function limitNumberOfNewLines(string, limit) {
  return string.split('\n').reduce((accumulator, current, i) => {
    let spacer = i > limit - 1 ? ' ' : '\n';
    return `${accumulator}${spacer}${current}`;
  });
}

export function getSupportedCurrencies(intl) {
  return [
    {
      code: 'AED',
      displayName: intl.t('currency.AED'),
      countryCode: 'AE',
      keywords: ['AED', 'AE', intl.t('currency.AED')],
      suggestionPriority: null,
      currencyCodeWithName: `AED - ${intl.t(`currency.AED`)}`,
    },
    {
      code: 'AUD',
      displayName: intl.t(`currency.AUD`),
      countryCode: 'AU',
      keywords: ['AUD', 'AU', intl.t(`currency.AUD`)],
      suggestionPriority: null,
      currencyCodeWithName: `AUD - ${intl.t(`currency.AUD`)}`,
    },
    {
      code: 'BGN',
      displayName: intl.t(`currency.BGN`),
      countryCode: 'BG',
      keywords: ['BGN', 'BG', intl.t(`currency.BGN`)],
      suggestionPriority: null,
      currencyCodeWithName: `BGN - ${intl.t(`currency.BGN`)}`,
    },
    {
      code: 'CAD',
      displayName: intl.t(`currency.CAD`),
      countryCode: 'CA',
      keywords: ['CAD', 'CA', intl.t(`currency.CAD`)],
      suggestionPriority: null,
      currencyCodeWithName: `CAD - ${intl.t(`currency.CAD`)}`,
    },
    {
      code: 'CHF',
      displayName: intl.t(`currency.CHF`),
      countryCode: 'CH',
      keywords: ['CHF', 'CH', intl.t(`currency.CHF`)],
      suggestionPriority: 26,
      currencyCodeWithName: `CHF - ${intl.t(`currency.CHF`)}`,
    },
    {
      code: 'CNY',
      displayName: intl.t(`currency.CNY`),
      countryCode: 'CN',
      keywords: ['CNY', 'CN', intl.t(`currency.CNY`)],
      suggestionPriority: null,
      currencyCodeWithName: `CNY - ${intl.t(`currency.CNY`)}`,
    },
    {
      code: 'CZK',
      displayName: intl.t(`currency.CZK`),
      countryCode: 'CZ',
      keywords: ['CZK', 'CZ', intl.t(`currency.CZK`)],
      suggestionPriority: null,
      currencyCodeWithName: `CZK - ${intl.t(`currency.CZK`)}`,
    },
    {
      code: 'DKK',
      displayName: intl.t(`currency.DKK`),
      countryCode: 'DK',
      keywords: ['DKK', 'DK', intl.t(`currency.DKK`)],
      suggestionPriority: null,
      currencyCodeWithName: `DKK - ${intl.t(`currency.DKK`)}`,
    },
    {
      code: 'EUR',
      displayName: intl.t(`currency.EUR`),
      countryCode: 'EU',
      keywords: ['EUR', 'EU', intl.t(`currency.EUR`)],
      suggestionPriority: null,
      currencyCodeWithName: `EUR - ${intl.t(`currency.EUR`)}`,
    },
    {
      code: 'GBP',
      displayName: intl.t(`currency.GBP`),
      countryCode: 'GB',
      keywords: ['GBP', 'GB', intl.t(`currency.GBP`)],
      suggestionPriority: 27,
      currencyCodeWithName: `GBP - ${intl.t(`currency.GBP`)}`,
    },
    {
      code: 'GHS',
      displayName: intl.t(`currency.GHS`),
      countryCode: 'GH',
      keywords: ['GHS', 'GH', intl.t(`currency.GHS`)],
      suggestionPriority: null,
      currencyCodeWithName: `GHS - ${intl.t(`currency.GHS`)}`,
    },
    {
      code: 'HKD',
      displayName: intl.t(`currency.HKD`),
      countryCode: 'HK',
      keywords: ['HKD', 'HK', intl.t(`currency.HKD`)],
      suggestionPriority: null,
      currencyCodeWithName: `HKD - ${intl.t(`currency.HKD`)}`,
    },
    {
      code: 'HUF',
      displayName: intl.t(`currency.HUF`),
      countryCode: 'HU',
      keywords: ['HUF', 'HU', intl.t(`currency.HUF`)],
      suggestionPriority: null,
      currencyCodeWithName: `HUF - ${intl.t(`currency.HUF`)}`,
    },
    {
      code: 'ILS',
      displayName: intl.t(`currency.ILS`),
      countryCode: 'IL',
      keywords: ['ILS', 'IL', intl.t(`currency.ILS`)],
      suggestionPriority: null,
      currencyCodeWithName: `ILS - ${intl.t(`currency.ILS`)}`,
    },
    {
      code: 'INR',
      displayName: intl.t(`currency.INR`),
      countryCode: 'IN',
      keywords: ['INR', 'IN', intl.t(`currency.INR`)],
      suggestionPriority: null,
      currencyCodeWithName: `INR - ${intl.t(`currency.INR`)}`,
    },
    {
      code: 'MXN',
      displayName: intl.t(`currency.MXN`),
      countryCode: 'MX',
      keywords: ['MXN', 'MX', intl.t(`currency.MXN`)],
      suggestionPriority: null,
      currencyCodeWithName: `MXN - ${intl.t(`currency.MXN`)}`,
    },
    {
      code: 'NOK',
      displayName: intl.t(`currency.NOK`),
      countryCode: 'NO',
      keywords: ['NOK', 'NO', intl.t(`currency.NOK`)],
      suggestionPriority: null,
      currencyCodeWithName: `NOK - ${intl.t(`currency.NOK`)}`,
    },
    {
      code: 'NZD',
      displayName: intl.t(`currency.NZD`),
      countryCode: 'NZ',
      keywords: ['NZD', 'NZ', intl.t(`currency.NZD`)],
      suggestionPriority: null,
      currencyCodeWithName: `NZD - ${intl.t(`currency.NZD`)}`,
    },
    {
      code: 'PEN',
      displayName: 'Peruvian Sol',
      countryCode: 'PE',
      keywords: ['PEN', 'PE', intl.t(`currency.PEN`)],
      suggestionPriority: null,
      currencyCodeWithName: `PEN - ${intl.t(`currency.PEN`)}`,
    },
    {
      code: 'PLN',
      displayName: 'Polish Złoty',
      countryCode: 'PL',
      keywords: ['PLN', 'PL', intl.t(`currency.PLN`)],
      suggestionPriority: null,
      currencyCodeWithName: `PLN - ${intl.t(`currency.PLN`)}`,
    },
    {
      code: 'RON',
      displayName: intl.t(`currency.RON`),
      countryCode: 'RO',
      keywords: ['RON', 'RO', intl.t(`currency.RON`)],
      suggestionPriority: null,
      currencyCodeWithName: `RON - ${intl.t(`currency.RON`)}`,
    },
    {
      code: 'RSD',
      displayName: intl.t(`currency.RSD`),
      countryCode: 'RS',
      keywords: ['RSD', 'RS', intl.t(`currency.RSD`)],
      suggestionPriority: null,
      currencyCodeWithName: `RSD - ${intl.t(`currency.RSD`)}`,
    },
    {
      code: 'SAR',
      displayName: intl.t(`currency.SAR`),
      countryCode: 'SA',
      keywords: ['SAR', 'SA', intl.t(`currency.SAR`)],
      suggestionPriority: null,
      currencyCodeWithName: `SAR - ${intl.t(`currency.SAR`)}`,
    },
    {
      code: 'SEK',
      displayName: intl.t(`currency.SEK`),
      countryCode: 'SE',
      keywords: ['SEK', 'SE', intl.t(`currency.SEK`)],
      suggestionPriority: null,
      currencyCodeWithName: `SEK - ${intl.t(`currency.SEK`)}`,
    },
    {
      code: 'SGD',
      displayName: intl.t(`currency.SGD`),
      countryCode: 'SG',
      keywords: ['SGD', 'SG', intl.t(`currency.SGD`)],
      suggestionPriority: null,
      currencyCodeWithName: `SGD - ${intl.t(`currency.SGD`)}`,
    },
    {
      code: 'TRY',
      displayName: intl.t(`currency.TRY`),
      countryCode: 'TR',
      keywords: ['TRY', 'TR', intl.t(`currency.TRY`)],
      suggestionPriority: null,
      currencyCodeWithName: `TRY - ${intl.t(`currency.TRY`)}`,
    },
    {
      code: 'USD',
      displayName: intl.t(`currency.USD`),
      countryCode: 'US',
      keywords: ['USD', 'US', intl.t(`currency.USD`)],
      suggestionPriority: 28,
      currencyCodeWithName: `USD - ${intl.t(`currency.USD`)}`,
    },
    {
      code: 'ZAR',
      displayName: intl.t(`currency.ZAR`),
      countryCode: 'ZA',
      keywords: ['ZAR', 'ZA', intl.t(`currency.ZAR`)],
      suggestionPriority: null,
      currencyCodeWithName: `ZAR - ${intl.t(`currency.ZAR`)}`,
    },
    {
      code: 'ZMW',
      displayName: intl.t(`currency.ZMW`),
      countryCode: 'ZM',
      keywords: ['ZMW', 'ZM', intl.t(`currency.ZMW`)],
      suggestionPriority: null,
      currencyCodeWithName: `ZMW - ${intl.t(`currency.ZMW`)}`,
    },
  ];
}

export function getSupportedUnits(intl) {
  // order needs to be respected as per list https://docs.google.com/spreadsheets/d/17Kf8ABSKmwRJZatz4SE3rWGvficMEPiUcJGDT5wSA2M/edit#gid=1978512896
  return [
    {
      unitCode: 'unit',
      unitDisplayName: `${intl.t(`unit.unit`)}`,
      keywords: ['unit', intl.t('unit.unit')],
      unitName: intl.t('unit.unit'),
      isService: true,
    },
    {
      unitCode: 'hour',
      unitDisplayName: `${intl.t(`unit.hour`)}`,
      keywords: ['hour', intl.t('unit.hour')],
      unitName: intl.t('unit.hour'),
      isService: true,
    },
    {
      unitCode: 'day',
      unitDisplayName: `${intl.t(`unit.day`)}`,
      keywords: ['day', intl.t('unit.day')],
      unitName: intl.t('unit.day'),
      isService: true,
    },
    {
      unitCode: 'month',
      unitDisplayName: `${intl.t(`unit.month`)}`,
      keywords: ['month', intl.t('unit.month')],
      unitName: intl.t('unit.month'),
      isService: true,
    },
    {
      unitCode: 'gram',
      unitDisplayName: `${intl.t(`unit.gram`)} - ${intl.t(`unit.long-form.gram`)}`,
      keywords: ['gram', intl.t('unit.gram'), intl.t(`unit.long-form.gram`)],
      unitName: intl.t('unit.gram'),
      unitLongName: intl.t('unit.long-form.gram'),
    },
    {
      unitCode: 'kilogram',
      unitDisplayName: `${intl.t(`unit.kilogram`)} - ${intl.t(`unit.long-form.kilogram`)}`,
      keywords: ['kilogram', intl.t('unit.kilogram'), intl.t(`unit.long-form.kilogram`)],
      unitName: intl.t('unit.kilogram'),
      unitLongName: intl.t('unit.long-form.kilogram'),
    },
    {
      unitCode: 'liter',
      unitDisplayName: `${intl.t(`unit.liter`)} - ${intl.t(`unit.long-form.liter`)}`,
      keywords: ['liter', intl.t('unit.liter'), intl.t(`unit.long-form.liter`)],
      unitName: intl.t('unit.liter'),
      unitLongName: intl.t('unit.long-form.liter'),
    },
    {
      unitCode: 'meter',
      unitDisplayName: `${intl.t(`unit.meter`)} - ${intl.t(`unit.long-form.meter`)}`,
      keywords: ['meter', intl.t('unit.meter'), intl.t(`unit.long-form.meter`)],
      unitName: intl.t('unit.meter'),
      unitLongName: intl.t('unit.long-form.meter'),
    },
    {
      unitCode: 'square_meter',
      unitDisplayName: `${intl.t(`unit.square_meter`)} - ${intl.t(`unit.long-form.square_meter`)}`,
      keywords: [
        'square_meter',
        intl.t('unit.square_meter'),
        intl.t(`unit.long-form.square_meter`),
      ],
      unitName: intl.t('unit.square_meter'),
      unitLongName: intl.t('unit.long-form.square_meter'),
    },
    {
      unitCode: 'cubic_meter',
      unitDisplayName: `${intl.t(`unit.cubic_meter`)} - ${intl.t(`unit.long-form.cubic_meter`)}`,
      keywords: ['cubic_meter', intl.t('unit.cubic_meter'), intl.t(`unit.long-form.cubic_meter`)],
      unitName: intl.t('unit.cubic_meter'),
      unitLongName: intl.t('unit.long-form.cubic_meter'),
    },
    {
      unitCode: 'ampere',
      unitDisplayName: `${intl.t(`unit.ampere`)}`,
      keywords: ['ampere', intl.t('unit.ampere')],
      unitName: intl.t('unit.ampere'),
    },
    {
      unitCode: 'number_of_articles',
      unitDisplayName: `${intl.t(`unit.number_of_articles`)}`,
      keywords: ['number_of_articles', intl.t('unit.number_of_articles')],
      unitName: intl.t('unit.number_of_articles'),
    },
    {
      unitCode: 'centimeter',
      unitDisplayName: `${intl.t(`unit.centimeter`)} - ${intl.t(`unit.long-form.centimeter`)}`,
      keywords: ['centimeter', intl.t('unit.centimeter'), intl.t(`unit.long-form.centimeter`)],
      unitName: intl.t('unit.centimeter'),
      unitLongName: intl.t('unit.long-form.centimeter'),
    },
    {
      unitCode: 'cubic_meter_per_hour',
      unitDisplayName: `${intl.t(`unit.cubic_meter_per_hour`)} - ${intl.t(
        `unit.long-form.cubic_meter_per_hour`
      )}`,
      keywords: [
        'cubic_meter_per_hour',
        intl.t('unit.cubic_meter_per_hour'),
        intl.t(`unit.long-form.cubic_meter_per_hour`),
      ],
      unitName: intl.t('unit.cubic_meter_per_hour'),
      unitLongName: intl.t('unit.long-form.cubic_meter_per_hour'),
    },
    {
      unitCode: 'gigajoule',
      unitDisplayName: `${intl.t(`unit.gigajoule`)}`,
      keywords: ['gigajoule', intl.t('unit.gigajoule')],
      unitName: intl.t('unit.gigajoule'),
    },
    {
      unitCode: 'gigawatt',
      unitDisplayName: `${intl.t(`unit.gigawatt`)}`,
      keywords: ['gigawatt', intl.t('unit.gigawatt')],
      unitName: intl.t('unit.gigawatt'),
    },
    {
      unitCode: 'gigawatt_hour',
      unitDisplayName: `${intl.t(`unit.gigawatt_hour`)}`,
      keywords: ['gigawatt_hour', intl.t('unit.gigawatt_hour')],
      unitName: intl.t('unit.gigawatt_hour'),
    },
    {
      unitCode: 'half_year',
      unitDisplayName: `${intl.t(`unit.half_year`)}`,
      keywords: ['half_year', intl.t('unit.half_year')],
      unitName: intl.t('unit.half_year'),
      isService: true,
    },
    {
      unitCode: 'joule',
      unitDisplayName: `${intl.t(`unit.joule`)}`,
      keywords: ['joule', intl.t('unit.joule')],
      unitName: intl.t('unit.joule'),
    },
    {
      unitCode: 'kilojoule',
      unitDisplayName: `${intl.t(`unit.kilojoule`)}`,
      keywords: ['kilojoule', intl.t('unit.kilojoule')],
      unitName: intl.t('unit.kilojoule'),
    },
    {
      unitCode: 'kilovar',
      unitDisplayName: `${intl.t(`unit.kilovar`)}`,
      keywords: ['kilovar', intl.t('unit.kilovar')],
      unitName: intl.t('unit.kilovar'),
    },
    {
      unitCode: 'kilowatt',
      unitDisplayName: `${intl.t(`unit.kilowatt`)}`,
      keywords: ['kilowatt', intl.t('unit.kilowatt')],
      unitName: intl.t('unit.kilowatt'),
    },
    {
      unitCode: 'kilowatt_hour',
      unitDisplayName: `${intl.t(`unit.kilowatt_hour`)}`,
      keywords: ['kilowatt_hour', intl.t('unit.kilowatt_hour')],
      unitName: intl.t('unit.kilowatt_hour'),
    },
    {
      unitCode: 'megajoule',
      unitDisplayName: `${intl.t(`unit.megajoule`)}`,
      keywords: ['megajoule', intl.t('unit.megajoule')],
      unitName: intl.t('unit.megajoule'),
    },
    {
      unitCode: 'megawatt',
      unitDisplayName: `${intl.t(`unit.megawatt`)}`,
      keywords: ['megawatt', intl.t('unit.megawatt')],
      unitName: intl.t('unit.megawatt'),
    },
    {
      unitCode: 'megawatt_hour',
      unitDisplayName: `${intl.t(`unit.megawatt_hour`)}`,
      keywords: ['megawatt_hour', intl.t('unit.megawatt_hour')],
      unitName: intl.t('unit.megawatt_hour'),
    },
    {
      unitCode: 'milligram',
      unitDisplayName: `${intl.t(`unit.milligram`)} - ${intl.t(`unit.long-form.milligram`)}`,
      keywords: ['milligram', intl.t('unit.milligram'), intl.t(`unit.long-form.milligram`)],
      unitName: intl.t('unit.milligram'),
      unitLongName: intl.t('unit.long-form.milligram'),
    },
    {
      unitCode: 'milliliter',
      unitDisplayName: `${intl.t(`unit.milliliter`)} - ${intl.t(`unit.long-form.milliliter`)}`,
      keywords: ['milliliter', intl.t('unit.milliliter'), intl.t(`unit.long-form.milliliter`)],
      unitName: intl.t('unit.milliliter'),
      unitLongName: intl.t('unit.long-form.milliliter'),
    },
    {
      unitCode: 'millimeter',
      unitDisplayName: `${intl.t(`unit.millimeter`)} - ${intl.t(`unit.long-form.millimeter`)}`,
      keywords: ['millimeter', intl.t('unit.millimeter'), intl.t(`unit.long-form.millimeter`)],
      unitName: intl.t('unit.millimeter'),
      unitLongName: intl.t('unit.long-form.millimeter'),
    },
    {
      unitCode: 'minute',
      unitDisplayName: `${intl.t(`unit.minute`)}`,
      keywords: ['minute', intl.t('unit.minute')],
      unitName: intl.t('unit.minute'),
      isService: true,
    },
    {
      unitCode: 'pair',
      unitDisplayName: `${intl.t(`unit.pair`)}`,
      keywords: ['pair', intl.t('unit.pair')],
      unitName: intl.t('unit.pair'),
    },
    {
      unitCode: 'quarter',
      unitDisplayName: `${intl.t(`unit.quarter`)}`,
      keywords: ['quarter', intl.t('unit.quarter')],
      unitName: intl.t('unit.quarter'),
      isService: true,
    },
    {
      unitCode: 'second',
      unitDisplayName: `${intl.t(`unit.second`)}`,
      keywords: ['second', intl.t('unit.second')],
      unitName: intl.t('unit.second'),
      isService: true,
    },
    {
      unitCode: 'set',
      unitDisplayName: `${intl.t(`unit.set`)}`,
      keywords: ['set', intl.t('unit.set')],
      unitName: intl.t('unit.set'),
    },
    {
      unitCode: 'ton',
      unitDisplayName: `${intl.t(`unit.ton`)} - ${intl.t(`unit.long-form.ton`)}`,
      keywords: ['ton', intl.t('unit.ton'), intl.t(`unit.long-form.ton`)],
      unitName: intl.t('unit.ton'),
      unitLongName: intl.t('unit.long-form.ton'),
    },
    {
      unitCode: 'two_week',
      unitDisplayName: `${intl.t(`unit.two_week`)}`,
      keywords: ['two_week', intl.t('unit.two_week')],
      unitName: intl.t('unit.two_week'),
      isService: true,
    },
    {
      unitCode: 'watt_hour',
      unitDisplayName: `${intl.t(`unit.watt_hour`)}`,
      keywords: ['watt_hour', intl.t('unit.watt_hour')],
      unitName: intl.t('unit.watt_hour'),
    },
    {
      unitCode: 'week',
      unitDisplayName: `${intl.t(`unit.week`)}`,
      keywords: ['week', intl.t('unit.week')],
      unitName: intl.t('unit.week'),
      isService: true,
    },
    {
      unitCode: 'year',
      unitDisplayName: `${intl.t(`unit.year`)}`,
      keywords: ['year', intl.t('unit.year')],
      unitName: intl.t('unit.year'),
      isService: true,
    },
  ];
}

export function getVatExemptionReasons(intl, legalCountry) {
  // Reasons are sorted in a specific order established by Product
  if (legalCountry === 'DE') {
    return [
      {
        key: 'S19.1',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.de.S19.1'),
      },
      {
        key: 'S4.1B',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.de.S4.1B'),
      },
      {
        key: 'S4.1A',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.de.S4.1A'),
      },
      { key: 'S4', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.de.S4') },
      { key: 'S13B', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.de.S13B') },
    ];
  } else if (legalCountry === 'FR') {
    return [
      {
        key: 'S293B',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.fr.S293B'),
      },
      {
        key: 'S262.1',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.fr.S262.1'),
      },
      {
        key: 'S259',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.fr.S259'),
      },
      { key: 'S283', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.fr.S283') },
      { key: 'S261', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.fr.S261') },
      { key: 'S262', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.fr.S262') },
      { key: 'S263', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.fr.S263') },
    ];
  } else if (legalCountry === 'ES') {
    return [
      { key: 'S122', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.es.S122') },
      { key: 'S25', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.es.S25') },
      { key: 'S21', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.es.S21') },
      { key: 'S69', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.es.S69') },
      { key: 'S20', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.es.S20') },
      {
        key: 'S84.1.2',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.es.S84.1.2'),
      },
    ];
  } else if (legalCountry === 'PT') {
    return [
      {
        key: 'S53',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.pt.S53'),
      },
      {
        key: 'S9.29.30',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.pt.S9.29.30'),
      },
      {
        key: 'S9.27.28',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.pt.S9.27.28'),
      },
      {
        key: 'S9.1-5',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.pt.S9.1-5'),
      },
    ];
  } else if (legalCountry === 'BE') {
    return [
      {
        key: 'S44.1',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.be.S44.1'),
      },
      {
        key: 'S44.3.11',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.be.S44.3.11'),
      },
      {
        key: 'S44.3.2',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.be.S44.3.2'),
      },
      {
        key: 'S44.3.4',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.be.S44.3.4'),
      },
      {
        key: 'S39bis',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.be.S39bis'),
      },
      {
        key: 'S56bis',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.be.S56bis'),
      },
      {
        key: 'S39',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.be.S39'),
      },
      { key: 'S21', label: intl.t('receivable-invoices.invoice-creation.tax-exemption.be.S21') },
    ];
  } else if (legalCountry === 'NL') {
    return [
      {
        key: 'S25',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.nl.S25'),
      },
      {
        key: 'S9',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.nl.S9'),
      },
      {
        key: 'S11',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.nl.S11'),
      },
      {
        key: 'S12',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.nl.S12'),
      },
    ];
  } else if (legalCountry === 'AT') {
    return [
      {
        key: 'S6.1.27',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.at.S6.1.27'),
      },
      {
        key: 'S11.1a',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.at.S11.1a'),
      },
      {
        key: 'S6.1.16',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.at.S6.1.16'),
      },
      {
        key: 'S6.1.13',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.at.S6.1.13'),
      },
      {
        key: 'S6.1.19',
        label: intl.t('receivable-invoices.invoice-creation.tax-exemption.at.S6.1.19'),
      },
    ];
  } else {
    return [
      { key: 'N1', label: 'N1 - Escluse ex art. 15' },
      {
        key: 'N2.1',
        label: 'N2.1 - Non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72',
      },
      { key: 'N2.2', label: 'N2.2 - Non soggette - altri casi' },
      { key: 'N3.1', label: 'N3.1 - Non imponibili - esportazioni' },
      { key: 'N3.2', label: 'N3.2 - Non imponibili - cessioni intracomunitarie' },
      {
        key: 'N3.3',
        label: 'N3.3 - Non imponibili - cessioni verso San Marino',
      },
      {
        key: 'N3.4',
        label: "N3.4 - Non imponibili - operazioni assimilate alle cessioni all'esportazione",
      },
      { key: 'N3.5', label: "N3.5 - Non imponibili - a seguito di dichiarazioni d'intento" },
      {
        key: 'N3.6',
        label:
          'N3.6 - Non imponibili - altre operazioni che non concorrono alla formazione del plafond',
      },
      { key: 'N4', label: 'N4 - Esenti' },
      { key: 'N5', label: 'N5 - Regime del margine / IVA non esposta in fattura' },
      {
        key: 'N6.1',
        label: 'N6.1 - Inversione contabile - cessione di rottami e altri materiali di recupero',
      },
      { key: 'N6.2', label: 'N6.2 - Inversione contabile - cessione di oro e argento puro' },
      { key: 'N6.3', label: 'N6.3 - Inversione contabile - subappalto nel settore edile' },
      { key: 'N6.4', label: 'N6.4 - Inversione contabile - cessione di fabbricati' },
      { key: 'N6.5', label: 'N6.5 - Inversione contabile - cessione di telefoni cellulari' },
      { key: 'N6.6', label: 'N6.6 - Inversione contabile - cessione di prodotti elettronici' },
      {
        key: 'N6.7',
        label: 'N6.7 - Inversione contabile - prestazioni comparto edile e settori connessi',
      },
      { key: 'N6.8', label: 'N6.8 - Inversione contabile - operazioni settore energetico' },
      { key: 'N6.9', label: 'N6.9 - Inversione contabile - altri casi' },
      {
        key: 'N7',
        label:
          'N7 - IVA assolta in altro stato UE (vendite a distanza ex art. 40 c. 3 e 4 e art. 41 c. 1 lett. b, DL 331/93; prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-sexies lett. f, g, art. 74-sexies DPR 633/72)',
      },
    ];
  }
}
