import ApplicationSerializer from 'qonto/serializers/application';

export default class OrganizationSubscriptionSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(payloadKey) {
    if (payloadKey === 'subscription') {
      let newPayloadKey = 'organization-subscription';
      return super.modelNameFromPayloadKey(newPayloadKey);
    } else {
      return super.modelNameFromPayloadKey(payloadKey);
    }
  }

  payloadKeyFromModelName() {
    return 'subscription';
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let { subscription } = payload;

    if (subscription && !subscription['id']) {
      let newPayload = payload;
      newPayload['subscription']['id'] = crypto.randomUUID();
      return super.normalizeResponse(store, primaryModelClass, newPayload, id, requestType);
    }
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
