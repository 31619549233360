import { variation } from 'ember-launch-darkly';

// eslint-disable-next-line @qonto/no-import-roles-constants
import { ROLES } from 'qonto/constants/membership';

/**
 * @typedef {import('./config-types').TopBannerConfig} TopBannerConfig
 */

/**
 * @type {Array<TopBannerConfig>}
 */
export const FRAUD_ALERT_CONFIG = [
  {
    id: 'fraud-alert',
    type: 'warning',
    message: (_, intl) => intl.t('topbar_msg.warning-fraud'),
    shouldDisplay: ({ organizationManager }) => {
      let { organization, membership } = organizationManager;

      return (
        variation('operational--fraud-alert-banner') &&
        organization.legalCountry === 'FR' &&
        [ROLES.OWNER, ROLES.ADMIN].includes(membership.role)
      );
    },
  },
];
