import { INSIGHT_LABELS } from 'qonto/constants/supplier-invoice';

export let getDueInvoicesInsightSubtitle = (insights, intlService) => {
  let isEstimated = Boolean(insights[INSIGHT_LABELS.totalDueFxCounter]);
  let invoicesCount = insights[INSIGHT_LABELS.totalDueCounter];
  let overdueInvoicesCount = insights[INSIGHT_LABELS.totalOverdueCounter];

  if (invoicesCount === 0) {
    return intlService.t('supplier-invoices.cockpit.to-pay-today.subtitle.no-invoices');
  }

  if (overdueInvoicesCount === 0) {
    if (isEstimated) {
      return intlService.t('supplier-invoices.cockpit.to-pay-today.subtitle.estimated-total', {
        invoicesCount,
      });
    }

    return intlService.t('supplier-invoices.cockpit.to-pay-today.subtitle.total', {
      invoicesCount,
    });
  }

  if (isEstimated) {
    return intlService.t(
      'supplier-invoices.cockpit.to-pay-today.subtitle.estimated-total-with-overdue',
      {
        invoicesCount,
        overdueInvoicesCount,
      }
    );
  }

  return intlService.t('supplier-invoices.cockpit.to-pay-today.subtitle.total-with-overdue', {
    invoicesCount,
    overdueInvoicesCount,
  });
};

export let getUpcomingInvoicesInsightSubtitle = (insights, intlService) => {
  let isEstimated = Boolean(insights[INSIGHT_LABELS.totalUpcomingFxCounter]);
  let invoicesCount = insights[INSIGHT_LABELS.totalUpcomingCounter];

  if (invoicesCount === 0) {
    return intlService.t('supplier-invoices.cockpit.upcoming.subtitle.no-invoices');
  }

  if (isEstimated) {
    return intlService.t('supplier-invoices.cockpit.upcoming.subtitle.estimated-total', {
      invoicesCount,
    });
  }

  return intlService.t('supplier-invoices.cockpit.upcoming.subtitle.total', {
    invoicesCount,
  });
};
