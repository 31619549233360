import {
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
} from 'qonto/constants/user-actions';

export function setupCards({ intl, store, organization, remainingDaysOfTrial }) {
  return {
    'kyc-action'() {
      let description =
        remainingDaysOfTrial > 0
          ? intl.t('user-actions.setup-cards.kyc.free-trial-active.subtitle', {
              amount: remainingDaysOfTrial,
            })
          : intl.t('user-actions.setup-cards.kyc.free-trial-ended.subtitle');

      return {
        heading: intl.t('user-actions.setup-cards.kyc.title'),
        description,
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          text: intl.t('user-actions.setup-cards.kyc.cta'),
          route: 'kyc.start',
          query: {
            trackingEventOrigin: 'upsize_banner',
          },
          model: organization,
        },
        illustration: {
          name: 'kyc',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.LOTTIE,
        },
        isPrimary: true,
      };
    },
    'top-up-action'() {
      return {
        heading: intl.t('user-actions.setup-cards.top-up-account.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'onboarding.topup.amount',
          query: {
            trackingEventOrigin: 'product_discovery',
          },
          model: organization,
        },
        illustration: {
          name: 'top-up',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        blockedBy: ['kyc-action'],
      };
    },
    'iban-access-action'() {
      let bankAccount = store.peekAll('bank-account').find(({ main }) => main);
      return {
        heading: intl.t('user-actions.setup-cards.share-iban.title', {
          legalCountry: organization.legalCountry,
        }),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'accounts.details',
          model: bankAccount.slug,
        },
        illustration: {
          name: 'iban-access',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
        blockedBy: ['kyc-action'],
      };
    },
    'kyb-onbehalf-action'() {
      return {
        showRequiredBadge: true,
        heading: intl.t('user-actions.required-cards.kyb-on-behalf.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'upload-power-of-attorney',
          model: organization.slug,
        },
        illustration: {
          name: 'kyb-pending',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      };
    },
    'flex-kyb-de-freelancers-action'() {
      return {
        showRequiredBadge: true,
        heading: intl.t('user-actions.required-cards.flex-kyb.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'flex-kyb',
          model: organization,
        },
        illustration: {
          name: 'kyb-pending',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      };
    },
    'kyb-association-action'() {
      return {
        showRequiredBadge: true,
        heading: intl.t('user-actions.required-cards.kyb-associations.title'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK,
          href: organization.associationTypeformUrl,
        },
        illustration: {
          name: 'kyb-pending',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      };
    },
  };
}
