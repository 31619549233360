import type { MonthlyBalance, MonthlyFlow } from 'qonto/react/components/cash-flow/api/balance';
import { utcToZonedDate } from '../chart';

export interface FlowTooltip {
  amount: number;
  count: number;
  variation: number;
}

export interface BalanceDisplay {
  netChange: number;
}

export interface CashflowTimevalueData {
  startingBalance: number;
  endingBalance: number;
  inflows: number;
  outflows: number;
}

export interface CashflowTooltip {
  balance: BalanceDisplay;
  inflows: FlowTooltip;
  outflows: FlowTooltip;
}

export interface CashflowTimevalue {
  time: Date;
  data: CashflowTimevalueData;
  display: CashflowTooltip;
}

export function buildCashflowTimevalue(
  inflows: MonthlyFlow,
  outflows: MonthlyFlow,
  balance: MonthlyBalance,
  timezone: string = 'Europe/Paris'
): CashflowTimevalue {
  const startingBalance = Number(balance?.data?.starting_balance ?? 0);
  const endingBalance = Number(balance?.data?.ending_balance ?? 0);
  const netChange = endingBalance - startingBalance;

  const inflowsAmount = Number(inflows?.data?.amount_sum ?? 0);
  const inflowsCount = Number(inflows?.data?.amount_count ?? 0);
  const outflowsAmount = Number(outflows?.data?.amount_sum ?? 0);
  const outflowsCount = Number(outflows?.data?.amount_count ?? 0);

  const inflowsVariation = Number(inflows?.data?.growth_rate ?? 0);
  const outflowsVariation = Number(outflows?.data?.growth_rate ?? 0);

  const data = {
    startingBalance,
    endingBalance,
    inflows: inflowsAmount,
    outflows: -outflowsAmount,
  };

  const display = {
    balance: { netChange },
    inflows: { amount: inflowsAmount, count: inflowsCount, variation: inflowsVariation },
    outflows: { amount: outflowsAmount, count: outflowsCount, variation: outflowsVariation },
  };

  return { time: utcToZonedDate(balance.start_date, timezone)!, data, display };
}
