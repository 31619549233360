import { FR_CREDITORS_CODE } from 'qonto/constants/mandates';

const DEFAULT_DELIM = ' ';
const { DGFIP, SIE, URSSAF } = FR_CREDITORS_CODE;

const UMR_PATTERNS = {
  [DGFIP]: /^(.{0,2})(.{0,5})(.{0,4})(.{0,5})(.{0,4})(.{0,5})(.{0,4})(.{0,5})/,
  [SIE]: /^(.{0,2})(.{0,5})(.{0,4})(.{0,5})(.{0,4})(.{0,5})(.{0,4})(.{0,5})(.?)/,
  [URSSAF]: /^(.{0,5})(.{0,5})(.{0,5})(.{0,5})(.{0,5})(.{0,5})(.{0,5})/,
};

export function setCaretPosition(element, oldValue, newValue) {
  let { selectionEnd } = element;

  let prevPos = selectionEnd;
  let newPos = prevPos;

  if (oldValue.length === prevPos) {
    newPos = newValue.length;
  }

  // to avoid caret jumping when editing value not at the end
  element.value = newValue;

  // set caret position
  element.setSelectionRange(newPos, newPos);
}

function setRLMCCaretPosition(element, oldValue, newValue) {
  let { selectionEnd } = element;

  let isAlreadyFormatted = /\(\d+\)/.test(oldValue);
  let newPos = isAlreadyFormatted ? selectionEnd : selectionEnd + 1;

  // to avoid caret jumping when editing value not at the end
  element.value = newValue;

  // set caret position
  element.setSelectionRange(newPos, newPos);
}

function getFormattedValue(rawValue, formatRegex, delim = DEFAULT_DELIM) {
  return rawValue?.replace(formatRegex, function () {
    let capturedGroups = [...arguments].slice(1, arguments.length - 2);
    return [...capturedGroups].filter(o => o).join(delim);
  });
}

export function maskInput(input, mask = 'X', padLeft = 4, padRight = 3) {
  if (!input || padLeft + padRight >= input.length) {
    return input;
  }

  input = input.replace(/[^a-zA-Z0-9•]/g, '');

  return `${input.slice(0, padLeft)}${mask.repeat(
    input.length - (padLeft + padRight)
  )}${input.slice(input.length - padRight)}`;
}

export function formatIban(iban, isIBANObfuscated) {
  if (!iban) {
    return iban;
  }

  let cleanedIban = iban
    .replace(/[^a-zA-Z0-9•]/g, '') //!\ in transfer request controller, IBAN are masked with dots
    .toUpperCase()
    .substring(0, 34)
    .replace(/(.{4})/g, `$1 `)
    .trim();

  return isIBANObfuscated ? cleanedIban.replace(/x/gi, '•') : cleanedIban;
}

export function formatBIC(bic) {
  return bic
    ?.replace(/[^a-zA-Z0-9]/g, '')
    .toUpperCase()
    .trim();
}

export function formatSortCode(sortCode) {
  if (!sortCode) {
    return sortCode;
  }

  let cleanedSortCode = sortCode.toUpperCase().replace(/[\W_]/g, '').substring(0, 6);
  return getFormattedValue(cleanedSortCode, /(.{0,2})(.{0,2})(.{0,2})/, '-');
}

export function formatUmr(umr, creditorCode) {
  if (!umr) {
    return umr;
  }

  let maxLength = [DGFIP, SIE].includes(creditorCode) ? 34 : 35;

  if (![DGFIP, SIE, URSSAF].includes(creditorCode)) {
    return umr
      .toUpperCase()
      .replace(/[^A-Z0-9/\-?:().,'+\s]/g, '')
      .substring(0, maxLength);
  }

  let cleanedUmr = umr
    .toUpperCase()
    .replace(/[^A-Z0-9/\-?:().,'+]/g, '')
    .substring(0, maxLength);

  return getFormattedValue(cleanedUmr, UMR_PATTERNS[creditorCode]);
}

export function formatIcs(ics, creditorCode) {
  if (!ics) {
    return ics;
  }

  let isFrGouvCreditor = [DGFIP, SIE, URSSAF].includes(creditorCode);
  let maxLength = isFrGouvCreditor ? 13 : 35;

  let cleanedIcs = ics
    .toUpperCase()
    .replace(/[^A-Z0-9/\-?:().,'+]/g, '')
    .substring(0, maxLength);

  if (!isFrGouvCreditor) {
    return cleanedIcs;
  }

  return getFormattedValue(cleanedIcs, /(.{0,2})(.{0,2})(.{0,3})(.{0,3})(.{0,3})/);
}

export function formatCheckCMC7(cmc7) {
  let cleanedValue = cmc7?.replace?.(/\D/g, '').substring(0, 31);
  return getFormattedValue(cleanedValue, /(\d{0,7})(\d{0,12})(\d{0,12})/);
}

export function formatCheckRLMC(rlmc) {
  let cleanedValue = rlmc?.replace?.(/\D/g, '').substring(0, 2);

  // when deleting the last char which is the closing ")" we need to remove the last digit as well
  if (/^\(\d+$/.test(rlmc)) {
    cleanedValue = cleanedValue?.slice(0, -1);
  }

  return cleanedValue?.replace(/(\d{1,2})/, '($1)');
}

export function formatPagopaNoticeNumber(noticeNumber) {
  return noticeNumber
    ?.replace(/[^a-zA-Z0-9]/g, '')
    .replace(/(.{4})/g, `$1 `)
    .trim();
}

export function handleFormatUmr(element, creditorCode) {
  let { value } = element;
  let newValue = formatUmr(value, creditorCode);
  setCaretPosition(element, value, newValue);
}

export function handleFormatIcs(element, creditorCode) {
  let { value } = element;
  let newValue = formatIcs(value, creditorCode);
  setCaretPosition(element, value, newValue);
}

export function handleFormatIban(element, isIBANObfuscated) {
  let { value } = element;
  let newValue = formatIban(value, isIBANObfuscated);
  setCaretPosition(element, value, newValue);
}

export function handleFormatBIC(element) {
  let { value } = element;
  let newValue = formatBIC(value);
  setCaretPosition(element, value, newValue);
}

export function handleFormatSortCode(element) {
  let { value } = element;
  let newValue = formatSortCode(value);
  setCaretPosition(element, value, newValue);
}

export function handleFormatCheckCMC7(element) {
  let { value } = element;
  let newValue = formatCheckCMC7(value);
  setCaretPosition(element, value, newValue);
}

export function handleFormatCheckRLMC(element) {
  let { value } = element;
  let newValue = formatCheckRLMC(value);
  setRLMCCaretPosition(element, value, newValue);
}

export function handleFormatPagopaNoticeNumber(element) {
  let { value } = element;
  let newValue = formatPagopaNoticeNumber(value);
  setCaretPosition(element, value, newValue);
}
